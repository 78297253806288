import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faLock } from '@fortawesome/free-solid-svg-icons'
import CustomeInput from '../components/custom-input';
import { updatePassword } from "../helpers/firebase";

const PasswordModal = ({open, onClose, ...props}) => {
    const [newPassword, setNewPassword] = useState('');

    const handleSet = async () => {
        await updatePassword(newPassword);
        onClose();
    }

    return (
        <Modal 
            open={open || false}
            onClose={onClose || function () {}}
            {...props}
        >
            <div className="c_modal ps_modal">
                <div className="sub_cmodal">
                    <div className="modal_header">
                        <div className="title">
                            Change Password
                        </div>
                        <div className="btn_close" onClick={() => onClose()}>
                            <FontAwesomeIcon className="fa_icon" icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal_content">
                        <div className="ct_cinputs">
                            <CustomeInput 
                                type="password"
                                autoFocus={true}
                                icon={faLock}
                                title="New password"
                                placeholder="Confirm new password"
                                onChange={value => setNewPassword(value)}
                            />
                        </div>
                        <button className="set_m_btn btn" onClick={() => handleSet()}>
                            Set
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    ) 
}

export default PasswordModal;