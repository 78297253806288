import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMarker, faCircle } from '@fortawesome/free-solid-svg-icons'

import Layout from "../components/layout";
import { getUser, setUser } from "../helpers/auth";
import { getUserMetaData } from "../helpers/firebase";
import PasswordModal from "../components/password-modal";
import JobTitleModal from "../components/job-title-modal";

const Profile = () => {
    const {uid, email} = getUser();
    const cemail = email ? email.slice(0, Math.floor(email.indexOf('@') / 2)) + new Array(email.indexOf('@') - Math.floor(email.indexOf('@') / 2) + 1).join('*') + email.slice(email.indexOf('@')) : '';

    const [userEmail, setUserEmail] = useState(cemail);
    const [userMetaData, setUserMetaData] = useState(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);

    useEffect(() => {
        if (uid) getUMData();

        return () => {}
    }, []);

    const getUMData = async () => {
        const userMetaData = await getUserMetaData(uid);
        setUserMetaData(userMetaData);
    }

    const handleShow = () => {
        setUserEmail(email);

        setTimeout(() => {
            setUserEmail(cemail);
        }, 1500);
    }
    
    return (
        <Layout>
            <PasswordModal 
                open={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
            />
            <JobTitleModal
                open={isJobTitleModalOpen}
                userMetaData={userMetaData}
                onClose={() => setIsJobTitleModalOpen(false)}
            />
            <div className="center_colm">
                <div className="center_colm_title">
                    Profile settings
                </div>
                <div className="ct_item">
                    <label>Sign in email</label>
                    <div className="ct_item_cn">
                        <div className="ct_item_l">
                            <p>{userEmail}</p>
                        </div>
                        <div className="ct_item_r" onClick={() => handleShow()}>
                            <span className="show_btn">Show</span>
                        </div>
                    </div>
                </div>
                <div className="ct_item">
                    <label>Password</label>
                    <FontAwesomeIcon className="fa_icon p_edit" icon={faMarker} onClick={() => setIsPasswordModalOpen(true)} />
                    <div className="ct_item_cn">
                        <div className="ct_item_l">
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                            <FontAwesomeIcon className="fa_icon p_icon" icon={faCircle} />
                        </div>
                        <div className="ct_item_r"></div>
                    </div>
                </div>
                <div className="ct_item">
                    <label>Job title</label>
                    <FontAwesomeIcon className="fa_icon p_edit" icon={faMarker} onClick={() => setIsJobTitleModalOpen(true)} />
                    <div className="ct_item_cn">
                        <div className="ct_item_l">
                            <p>{userMetaData ? userMetaData.jobTitle : ''}</p>
                        </div>
                        <div className="ct_item_r"></div>
                    </div>
                </div>
                <div className="ct_item">
                    <label>Default call in country/region</label>
                    <div className="ct_item_cn">
                        <div className="ct_item_l">
                            <p>{userMetaData ? userMetaData.country : ''}</p>
                        </div>
                        <div className="ct_item_r"></div>
                    </div>
                </div>
                <div className="ct_item">
                    <label>User type</label>
                    <div className="ct_item_cn">
                        <div className="ct_item_l">
                            <p>{userMetaData ? userMetaData.userType : ''}</p>
                        </div>
                        <div className="ct_item_r"></div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Profile